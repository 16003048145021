<template>
  <div>
    <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mb-2">
      Không yêu cầu kích thước
      <div class="transparent--text">Không yêu cầu kích thước</div>
    </div>

    <tp-input-image
      :acceptedFileTypes="['image/*']"
      :maxFileSize="500 * 1024"
      :src.sync="promotion.background"
      allowCompress
      allowFileSize
      allowFileTypeValidation
      class="flex-grow-1"
      forceType="jpeg"
    ></tp-input-image>
  </div>
</template>

<script>
export default {
  computed: {
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    }
  }
};
</script>
