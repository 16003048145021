<template>
  <div>
    <div class="d-flex">
      <div class="flex-1 mr-5">
        <card-title-and-code></card-title-and-code>

        <v-card class="px-5 py-5 mt-5 d-flex promotion-type-wrapper" flat>
          <div class="flex-1">
            <div class="font-weight-bold mb-2">Giá trị khuyến mại</div>

            <div class="d-flex">
              <v-select
                v-model="promotion.detail.tangkhach_type_detail.tang_type"
                :items="[
                  { label: 'Số tiền', value: 3 },
                  { label: 'Phần trăm', value: 2 }
                ]"
                class="text-body-1"
                dense
                hide-details
                item-text="label"
                item-value="value"
                outlined
                style="max-width: 50%"
              ></v-select>

              <tp-text-field
                v-if="promotion.detail.tangkhach_type_detail.tang_type === 2"
                v-model.number="
                  promotion.detail.tangkhach_type_detail.tang_value
                "
                class="ml-3"
                hide-details="auto"
                placeholder="Nhập giá trị"
                suffix="%"
                type="number"
                validate="required|minVal:0|maxVal:99"
              ></tp-text-field>

              <tp-input-price
                v-if="promotion.detail.tangkhach_type_detail.tang_type === 3"
                v-model="promotion.detail.tangkhach_type_detail.tang_value"
                :empty-value="null"
                custom-class="ml-3"
                hide-details="auto"
                placeholder="Nhập giá trị"
                validate="required"
              ></tp-input-price>
            </div>

            <template
              v-if="promotion.detail.tangkhach_type_detail.tang_type === 2"
            >
              <div class="font-weight-bold mt-4 mb-2">
                Giảm tối đa
              </div>

              <tp-input-price
                v-model="promotion.detail.tangkhach_type_detail.max_value"
                :empty-value="null"
                hide-details="auto"
                placeholder="Nhập giá trị giảm tối đa"
                style="width: 50%"
              ></tp-input-price>
            </template>

            <div class="font-weight-bold mt-4 mb-2">
              Yêu cầu mua tối thiểu từ
            </div>

            <tp-input-price
              v-model="promotion.detail.apdung_type_detail.from_money"
              :empty-value="null"
              hide-details="auto"
              placeholder="Nhập giá trị"
              style="width: 50%"
            ></tp-input-price>
          </div>

          <v-divider class="mx-5" vertical></v-divider>

          <card-other-info class="flex-1"></card-other-info>
        </v-card>

        <v-card class="px-5 py-5 mt-5 d-flex promotion-type-wrapper" flat>
          <div>
            <div class="font-weight-bold mb-2">Cover</div>

            <card-upload-cover
              class="card-upload-thumbnail-wrapper"
            ></card-upload-cover>
          </div>

          <div class="ml-5">
            <div class="font-weight-bold mb-2">Thumbnail</div>

            <card-upload-thumbnail
              class="card-upload-thumbnail-wrapper"
            ></card-upload-thumbnail>
          </div>

          <div class="ml-5">
            <div class="font-weight-bold mb-2">Background</div>

            <card-upload-background
              class="card-upload-thumbnail-wrapper"
            ></card-upload-background>
          </div>

          <div class="ml-5">
            <div class="font-weight-bold mb-2">Frame</div>

            <card-upload-frame
              class="card-upload-thumbnail-wrapper"
            ></card-upload-frame>
          </div>
        </v-card>
      </div>

      <div>
        <right-side-bar class="h-full right-sidebar-wrapper"></right-side-bar>
      </div>
    </div>
  </div>
</template>

<script>
import CardOtherInfo from "./components/CardOtherInfo";
import CardTitleAndCode from "./components/CardTitleAndCode";
import RightSideBar from "../RightSidebar/index.vue";
import CardUploadCover from "../MainDetail/components/CardUploadCover.vue";
import CardUploadThumbnail from "../MainDetail/components/CardUploadThumbnail.vue";
import CardUploadFrame from "../MainDetail/components/CardUploadFrame.vue";
import CardUploadBackground from "../MainDetail/components/CardUploadBackground.vue";

export default {
  components: {
    CardUploadFrame,
    CardUploadThumbnail,
    CardUploadCover,
    CardUploadBackground,
    RightSideBar,
    CardOtherInfo,
    CardTitleAndCode
  },

  computed: {
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    }
  }
};
</script>

<style scoped>
.right-sidebar-wrapper {
  width: 355px;
  height: 590px;
  overflow: auto;
}

.promotion-type-wrapper {
  height: 430px;
}

.card-upload-thumbnail-wrapper {
  width: 265px;
}
</style>
